import { graphql, PageProps } from 'gatsby';
import React, { useEffect } from 'react';

import { Box } from '../../components/Core';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';


type IPhysiotecTemplate = PageProps<Queries.PagePhysiotecQuery, IPageContext>;

const PhysiotecPageTemplate: FCWithClassName<IPhysiotecTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  if (!pageData || !pageContext.defaultLanguage) return null;

  useEffect(() => {
    const username = new URL(window.location.toString()).searchParams.get(
      'username'
    );
    const src = username
      ? `https://hep.physiotec.ca/?exitIframe=1&username=${username}`
      : 'https://hep.physiotec.ca/?exitIframe=1';
    document.getElementById(
      'physiotec-container'
    ).innerHTML = `<iframe style="width:100%;height:800px;border:none;" src="${src}">`;
  }, []);
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Box id="physiotec-container" className="pt-0 w-full h-[800px]" />
    </Layout>
  );
};

export default PhysiotecPageTemplate;

export const query = graphql`
  query PagePhysiotec($id: String) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
    }
  }
`;
